import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-assets',
  template: '',
})
export class AssetsComponent implements OnInit{

  constructor() {
  }

  ngOnInit() {
  }

}
