<footer>
  <div class="footer-top">
    <div class="footer-section footer-logo-container">
      <img class="footer-logo" src="assets/images/logos/moh-logo-emblem-only.png" alt="">
      <div>
        <p>MINISTRY OF HEALTH</p>
        <a href="https://www.health.go.ke/" target="_blank">www.health.go.ke</a>
      </div>
    </div>

    <div class="footer-section terms-container">
      <p><span class="quick">Quick</span>&nbsp;<span>Links</span></p>
      <a>FAQs</a>
      <a>Terms and Conditions</a>
    </div>
    <div class="footer-section terms-container">
      <a><span class="quick">Help</span>&nbsp;<span> & Support</span></a>
      <div class="footerItems">
        <i class='bx bx-help-circle footerIcons'></i>
        <a [routerLink]="['/support/']">Go to help page</a>
      </div>
      <div class="footerItems">
        <i class='bx bx-mobile footerIcons'></i>
        <a>020-00000</a>
      </div>
      <div class="footerItems">
        <i class='bx bxs-envelope footerIcons'></i>
        <a href="mailto:support@shield.health.go.ke">support@shield.health.go.ke</a>
      </div>
      <div class="footerItems">
        <i class='bx bx-home footerIcons'></i>
        <div>
          <a>MINISTRY OF HEALTH, Afya House, Cathedral Road</a>
        </div>
      </div>
    </div>
    <!--    <div class="shield-logo-container">-->
    <!--      <img class="shield-logo" src="assets/images/logos/logo-shield-footer.png">-->
    <!--      <p class="logo-text">Surveillance Health Information Exchange and Linkage Data-->
    <!--      <p>-->
    <!--    </div>-->
  </div>

  <div class="copyright">
    <p>
      @ {{ currentYear }} Division of Disease Surveillance and Response . All rights reserved
    </p>
  </div>
</footer>
