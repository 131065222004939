export const config = {
    FILE_PATH: "",
    API_ENDPOINT: "https://kratos-demo.icapkenya.org/api/v1/",
    SUMMARIZED_IFRAME_SOURCES: [],
    SUPERSET: {
      DOMAIN: 'https://demo-analytics.icapkenya.org',
      API_ENDPOINT: "https://demo-analytics.icapkenya.org/api/v1/security",
      API_PROVIDER: "db",
      USER_ADMINISTRATOR: {
        USERNAME: "demo_admin",
        PASSWORD: "AwesomeDemo2024#"
      },
      USER_GUEST: {
          USERNAME: "guest",
      },
      DASHBOARDS: [
        "fbd9fff0-2c46-4386-aaaf-75f68f94b2fe", // IBS
        "66df4175-2cc3-433e-b2e0-b6f7a99f4ef0", // IFBS/AFI
        "ca2c1d43-33d9-412e-9035-eca9d2d3cd1a", // SARI/ILI
        "24445a2a-48f0-4d72-9243-29d26707a095", // COVID-19
        "6d70e77c-b7d4-4bd4-bb53-82f26b0c81c2", // EBS
        "1aa39248-9cd3-4fa2-9ee9-4269a8a6690c", // OUTBREAK
        "931d6a99-36b5-48b6-ae95-a50ed3e037e2" // EBridge
      ],
    }
};

