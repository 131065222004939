<mat-sidenav-container
  class="mainWrapper blue_theme light-theme container"
  autosize
  autoFocus
  dir="ltr" style="background-color: #ffffff"
  >

  <mat-sidenav-content class="" >
    <main class="" style="background-color: #ffffff">

      <app-header
        [showToggle]="!isOver"
        (toggleCollapsed)="toggleCollapsed()"
        (toggleMobileNav)="sidenav.toggle()"
      ></app-header>


      <div>

          <router-outlet></router-outlet>
      </div>

    </main>

    <app-footer>
    </app-footer>
  </mat-sidenav-content>

</mat-sidenav-container>

